// Copyright (C) 2023 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'precision' })
export class PrecisionPipe implements PipeTransform {
  transform(value: number, decimalPlaces = 4): string {
    if (value === null || value === undefined) {
      return '-';
    }
    if (value >= 0.0001) {
      return value.toFixed(decimalPlaces);
    }
    if (value > 0 && value < 0.0001) {
      return '<0.0001';
    }
    if (value === 0) {
      return '0';
    }
    if (value < 0) {
      return value.toFixed(decimalPlaces);
    }

    console.error('Not expexted value: ', String(value));
    return '-';
  }
}
