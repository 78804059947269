// Copyright (C) 2021 Fair Supply Analytics Pty Ltd - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and confidential.
import { Pipe, PipeTransform } from '@angular/core';

import { toProperCase } from '../util';

@Pipe({
  name: 'properCase',
})
export class ProperCasePipe implements PipeTransform {
  transform(value: string, firstLetterOnly = false): string {
    return toProperCase(value, firstLetterOnly);
  }
}
